.App {
  margin-left: 1rem;
}

/* AG grid css options */
.ag-cell-focus,.ag-cell-no-focus{
  border:none !important;
}

.ag-theme-material, .ag-paging-panel{
  border-top: none !important;
}

.ag-row-selected {
  background-color: aliceblue!important;
}

.cell-wrap-text {
  white-space: normal !important;
}

.DateInput_input {
  font-size: 12px !important;
  width: 52% !important;
}

.MuiInput-root {
  text-align: center;
}

/**
This is to show the filter icon on ag-grid by default, instead of making it visible on hover
*/
.ag-header-icon,.ag-header-cell-menu-button {
  opacity: 1!important;
}

/**
This is for setting up min-height of ag grid table when the domlayout is set to autoHeight
In general when the domLayout is set to autoHeight, the min-Height is around 50px only
*/
.ag-body-viewport.ag-layout-auto-height .ag-center-cols-clipper, .ag-body-viewport.ag-layout-auto-height .ag-center-cols-container {
  min-height: 400px!important;
}

/**
This is for removing padding and boxshadow for ag grid loader image
*/
.ag-theme-material .ag-overlay-loading-center {
  border-radius: 0px!important;
  box-shadow: none!important;
  padding: 0px!important;
}

/**
This is for removing the spinner from textfield input when the type is set to number (for increment and decrement)
*/
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    /* -moz-appearance: textfield; */
    appearance: none;
    margin: 0; 
}

input[type=number] {
  -moz-appearance: textfield;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.loaderDiv {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}


::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}


::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 

::-webkit-scrollbar-thumb {
  background: #888; 
}


::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.ag-header-cell {
  color: #008081;
  border-top: 2px solid #eee;
  /* border-right: 2px dashed #eee; */
}

.grey-header .ag-header-viewport {
  background: #eee;
}
